/**
 * SparkReceipt frontend configuration
 */

import { en, fi, fr, ja, de, es } from "./locales";

export const configuration = {
  languages: {
    ui: {
      cache: {
        key: "language",
        sources: ["localStorage", "cookie"],
      },
      debug: false,
      fallback: "en",
      resources: {
        de,
        en,
        fi,
        fr,
        ja,
        es,
      },
    },
  },

  links: {
    privacyPolicy: {
      fi: "https://link-to-privacy-policy",
      en: "https://link-to-privacy-policy",
    },

    termsOfService: {
      fi: "https://link-to-tos",
      en: "https://link-to-tos",
    },

    mobileApp: {
      android: "https://api.app.sparkreceipt.com/fwd/download-app?platform=android&ref=web_app",
      ios: "https://api.app.sparkreceipt.com/fwd/download-app?platform=ios&ref=web_app",
    },
  },

  numbers: {
    en: {
      decimalSeparator: ".",
      groupingSymbol: ",",
    },
    fi: {
      decimalSeparator: ",",
      groupingSymbol: " ",
    },
    de: {
      decimalSeparator: ",",
      groupingSymbol: " ",
    },
    fr: {
      decimalSeparator: ",",
      groupingSymbol: " ",
    },
    ja: {
      decimalSeparator: ".",
      groupingSymbol: ",",
    },
    es: {
      decimalSeparator: ",",
      groupingSymbol: ".",
    },
  },

  textFieldLimits: {
    register: {
      email: 200,
    },
    organization: {
      name: 70,
    },
    user: {
      name: 30,
    },
    document: {
      name: 50,
      description: 500,
    },
  },
};
