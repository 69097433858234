import React from "react";
import PropTypes from "prop-types";

import "./button.scss";
import { useNavigate } from "react-router";

const Button = ({
  children,
  className = "",
  type = "button",
  theme = "primary",
  size = "default",
  tag = "button",
  href = null,
  badge = null,
  icon = null,
  collapseOnMobile = false,
  onClick = () => {},
  disabled = false,
  style = {},
}) => {
  let ButtonTag = tag;
  let navigate = null;

  if (href) {
    navigate = useNavigate();
    ButtonTag = "a";
  }

  return (
    <ButtonTag
      className={`button ${className} button-${theme} button-${size} ${
        !children ? "no-content" : ""
      } ${disabled ? "button-disabled" : ""} ${badge ? "has-badge" : ""} ${
        collapseOnMobile ? "collapse-on-mobile" : ""
      }`}
      type={type}
      href={href}
      disabled={disabled}
      onClick={(e) => {
        if (disabled) {
          e.preventDefault();
          return;
        }
        if (href && !onClick) {
          navigate(href);
          return;
        }
        onClick(e);
      }}
      style={style}
    >
      {icon && <span className="button-icon">{icon}</span>}
      <span className="button-content">{children}</span>
      {badge && <span className="badge">{badge}</span>}
    </ButtonTag>
  );
};

Button.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  tag: PropTypes.string,
  icon: PropTypes.any,
  badge: PropTypes.string,
  theme: PropTypes.oneOf(["primary", "subtle", "link", "outlined", "plain"]),
  size: PropTypes.oneOf(["default", "small"]),
  href: PropTypes.string,
  style: PropTypes.object,
  collapseOnMobile: PropTypes.bool,
};

export default Button;
